import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Result
      status='404'
      title='404'
      subTitle='Ups, la pagina que busca no se ha encontrado ...'
      extra={
        <>
          <img src='' />
          <Button
            onClick={handleBack}
            style={{
              backgroundColor: '#fdbf94',
              borderRadius: '30px',
              fontSize: 'medium',
              height: '5vh'
            }}
            type='primary'
          >
            Volver atrás
          </Button>
        </>
      }
    />
  );
};

export default PageNotFound;
