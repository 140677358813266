import React, { createContext, useContext, useState } from 'react';

const PageStateContext = createContext();

export const usePageState = () => useContext(PageStateContext);

export const PageStateProvider = ({ children }) => {
  const [pageState, setPageState] = useState({
    showList: false,
    showDeliveryList: false,
    category: '',
    isHidden: true,
    selectedCategory: ''
  });

  return <PageStateContext.Provider value={{ pageState, setPageState }}>{children}</PageStateContext.Provider>;
};
