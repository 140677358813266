import './styles/Map.css';
import './styles/VSL.css';
import GoogleMapReact from 'google-map-react';
import { useState, useMemo } from 'react';
import { Modal } from 'antd';
import { Image } from 'antd';
import { Flex } from 'antd';
import VetStoreList from './components/VetStoreList';
import { useNavigate } from 'react-router-dom';
import { iconMarker, iconMap, iconDetail } from './components/IconConfig';
import AutocompleteComponent from './components/AutoCompleteExtend';
import { usePageState } from './components/StateContextPage';
import ModalForInput from './components/ModalForInput';

const coordsAntofa = { lat: -23.648881595738356, lng: -70.3981352609094 };

function Map({ stores, coords, onSearch }) {
  const [mapZoom, setMapZoom] = useState(15);
  const [mapCoords, setMapsCoords] = useState(coords || coordsAntofa);
  const [showModal, setShowModal] = useState({ modalId: '', show: false });
  const { pageState, setPageState } = usePageState();
  const [visible, setVisible] = useState(false);
  let radio = mapZoom;

  const onPinClick = (coords) => {
    setMapsCoords({ lat: parseFloat(coords.lat), lng: parseFloat(coords.lng) });
    setShowModal({ modalId: coords.modalId, show: true });
    setMapZoom(18);
  };

  const onCategoryClick = (type) => {
    console.log(type);
    setPageState((prevState) => ({
      ...prevState,
      category: prevState.category === type ? '' : type
    }));
  };

  let categoryKeyword = {
    vet: 'vet',
    shop: 'petshop',
    funerary: 'funeral',
    hairsalon: 'hairsalon',
    pharmacy: 'pharmacy',
    clinic: 'clinic',
    food: 'foodstore',
    training: 'training',
    petwalk: 'petwalk',
    hotel: 'hotel',
    transportation: 'transportation'
  };

  /** Calcula la distancia entre 2 coordenadas en km */
  const Distancia = (coords1, coords2) => {
    const degreesToRad = (degrees) => (degrees * Math.PI) / 180;
    const earthRadiusKm = 6371;
    const deltaLatitude = degreesToRad(coords2.lat - coords1.lat);
    const deltaLongitude = degreesToRad(coords2.lng - coords1.lng);
    const latitude1 = degreesToRad(coords1.lat);
    const latitude2 = degreesToRad(coords2.lat);
    const haversineFormula =
      Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
      Math.sin(deltaLongitude / 2) * Math.sin(deltaLongitude / 2) * Math.cos(latitude1) * Math.cos(latitude2);
    const angularDistance = 2 * Math.atan2(Math.sqrt(haversineFormula), Math.sqrt(1 - haversineFormula));
    return earthRadiusKm * angularDistance;
  };

  /**
   * Filtra los storeType y principalStoreType en base al category seleccionado por el usuario.
   * Al entrar en la app sin seleccion de filtro muestra vets y stores.
   * Almacena el keyword del storeType de la categoria en base al objeto categoryKeyword.
   * Unifica los array vets y stores usando operadores de propagacion.
   * Filtra las tiendas y servicios por PST y ST unificando los resultados.
   */
  /* SOLO PARA EL MAPA*/
  const dataList = useMemo(() => {
    let allEntities = stores.filter((e) => !e.onlyDelivery && e.active);
    let entitiesWhithCoords = allEntities.filter((e) => e.coordX && e.coordY);
    if (pageState.category === '') {
      return entitiesWhithCoords.filter((e) => e.principalStoreType);
    } else {
      let keyword = categoryKeyword[pageState.category];
      let filteredByPST = entitiesWhithCoords.filter((e) => e.principalStoreType && e.principalStoreType.keyword === keyword);
      let filteredByST = entitiesWhithCoords.filter((e) => e.storeType && e.storeType.some((st) => st.keyword === keyword));

      return [...filteredByPST, ...filteredByST];
    }
  }, [stores, pageState.category]);

  /**
   * Solo deja los servicios que tienen delivery y calcula el radio que sea menor a 6km
   */
  const entitiesWithDelivery = useMemo(() => {
    let DeliveryEntities = stores.filter((e) => (e.deliveryService || e.onlyDelivery) && e.active);
    let entitiesWhithCoords = DeliveryEntities.filter((e) => e.coordX && e.coordY);
    let filteredEntities = entitiesWhithCoords.filter((e) => Distancia({ lat: e.coordX, lng: e.coordY }, mapCoords) <= radio);
    if (pageState.category === '') {
      return filteredEntities.filter((e) => e.principalStoreType);
    } else {
      let keyword = categoryKeyword[pageState.category];
      let filteredByPST = filteredEntities.filter((e) => e.principalStoreType && e.principalStoreType.keyword === keyword);
      let filteredByST = filteredEntities.filter((e) => e.storeType && e.storeType.some((st) => st.keyword === keyword));

      return [...filteredByPST, ...filteredByST];
    }
  }, [stores, pageState.category, mapCoords, radio]);

  /* SOLO PARA LA LISTA INCLUYE TODOS */
  const allList = useMemo(() => {
    let allEntities = stores.filter((e) => e.active);
    let entitiesWhithCoords = allEntities.filter((e) => e.coordX && e.coordY);
    let filteredEntities = entitiesWhithCoords.filter((e) => Distancia({ lat: e.coordX, lng: e.coordY }, mapCoords) <= radio);
    if (pageState.category === '') {
      return filteredEntities.filter((e) => e.principalStoreType);
    } else {
      let keyword = categoryKeyword[pageState.category];
      let filteredByPST = filteredEntities.filter((e) => e.principalStoreType && e.principalStoreType.keyword === keyword);
      let filteredByST = filteredEntities.filter((e) => e.storeType && e.storeType.some((st) => st.keyword === keyword));

      return [...filteredByPST, ...filteredByST];
    }
  }, [stores, pageState.category]);

  const handleCancel = (modalId) => {
    setShowModal({ modalId, show: false });
    setMapZoom(15);
  };

  const navigate = useNavigate();

  const handleList = () => {
    setPageState((prevState) => ({
      ...prevState,
      showList: !prevState.showList,
      showDeliveryList: false
    }));
  };

  const handleDeliveryListToggle = () => {
    setPageState((prevState) => ({
      ...prevState,
      showDeliveryList: !prevState.showDeliveryList,
      showList: false
    }));
  };

  const handleCategoryClick = (category) => {
    setPageState((prevState) => ({
      ...prevState,
      selectedCategory: category
    }));
    onCategoryClick(category);
  };

  const handleExpandClick = () => {
    setPageState((prevState) => ({
      ...prevState,
      isHidden: !prevState.isHidden
    }));
  };

  const showInputModal = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <div className='input-autocomplete'>
        <AutocompleteComponent onSearch={onSearch} />
      </div>
      <div style={{ height: '100vh', width: '100vw' }}>
        <div className='fake-modal-1'>
          <button className='input-change-btn' onClick={showInputModal}>
            <span>modal</span>
          </button>
          <ModalForInput visible={visible} onClose={handleClose} onSearch={onSearch} />
          <Flex vertical={false}>
            <img src='cute.png' width={80} height={80} />
            <Flex vertical={true}>
              <span className='txt-fake-modal-1'>Hola! ^^</span>
              <span className='txt-fake-modal-1'>¿Qué buscas hoy?</span>
              <button className={`entity-list-btn ${pageState.showList ? 'selected' : ''}`} onClick={handleList}>
                <span className='txt-btn-all'>Todos los servicios</span>
              </button>
              <button className={`filter-domi-btn ${pageState.showDeliveryList ? 'selected' : ''}`} onClick={handleDeliveryListToggle}>
                <span className='txt-btn-delivery'>Servicios a Domicilio</span>
              </button>
            </Flex>
          </Flex>
        </div>
        <div className={`list-component ${pageState.showList || pageState.showDeliveryList ? 'show' : ''}`}>
          {pageState.showList && <VetStoreList dataList={allList} category={pageState.category} />}
          {pageState.showDeliveryList && <VetStoreList dataList={entitiesWithDelivery} category={pageState.category} />}
        </div>
        <div className='fake-modal-2'>
          <Flex className='flex-container' vertical={true}>
            <button className='btn-expand' onClick={handleExpandClick}>
              {pageState.isHidden ? <span>{'>'}</span> : <span>{'<'}</span>}
            </button>
            <Flex className='flex-item' justify='space-evenly'>
              <div className={`icon-container ${pageState.selectedCategory === 'vet' ? 'selected' : ''}`}>
                <img
                  src='/icons-cremen-svg/clinic.svg'
                  onClick={() => handleCategoryClick('vet')}
                  className={`${pageState.category == 'vet' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Veterinarias</p>
              </div>
              <div className={`icon-container ${pageState.selectedCategory === 'food' ? 'selected' : ''}`}>
                <img
                  src='/icons-cremen-svg/alimento.svg'
                  onClick={() => handleCategoryClick('food')}
                  className={`${pageState.category == 'food' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Tiendas de alimento</p>
              </div>
              <div className={`icon-container ${pageState.selectedCategory === 'funerary' ? 'selected' : ''}`}>
                <img
                  src='/icons-cremen-svg/cementerio.svg'
                  onClick={() => handleCategoryClick('funerary')}
                  className={`${pageState.category == 'funerary' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Funerarias</p>
              </div>
              <div
                className={`icon-container-ext ${pageState.isHidden ? 'hidden' : 'visible'} ${pageState.selectedCategory === 'training' ? 'selected' : ''}`}
              >
                <img
                  src='/icons-cremen-svg/learn.svg'
                  onClick={() => handleCategoryClick('training')}
                  className={`${pageState.category == 'training' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Adiestramiento canino</p>
              </div>
              <div
                className={`icon-container-ext ${pageState.isHidden ? 'hidden' : 'visible'} ${pageState.selectedCategory === 'petwalk' ? 'selected' : ''}`}
              >
                <img
                  src='/icons-cremen-svg/paseo.svg'
                  onClick={() => handleCategoryClick('petwalk')}
                  className={`${pageState.category == 'petwalk' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Paseo de mascotas</p>
              </div>
            </Flex>
            <Flex className='flex-item' justify='space-evenly'>
              <div className={`icon-container ${pageState.selectedCategory === 'hairsalon' ? 'selected' : ''}`}>
                <img
                  src='/icons-cremen-svg/cut.svg'
                  onClick={() => handleCategoryClick('hairsalon')}
                  className={`${pageState.category == 'hairsalon' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Peluquerías</p>
              </div>
              <div className={`icon-container ${pageState.selectedCategory === 'shop' ? 'selected' : ''}`}>
                <img
                  src='/icons-cremen-svg/shop.svg'
                  onClick={() => handleCategoryClick('shop')}
                  className={`${pageState.category == 'shop' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Tiendas de accesorios</p>
              </div>
              <div className={`icon-container ${pageState.selectedCategory === 'pharmacy' ? 'selected' : ''}`}>
                <img
                  src='/icons-cremen-svg/farma.svg'
                  onClick={() => handleCategoryClick('pharmacy')}
                  className={`${pageState.category == 'pharmacy' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Farmacias</p>
              </div>
              <div
                className={`icon-container-ext ${pageState.isHidden ? 'hidden' : 'visible'} ${pageState.selectedCategory === 'hotel' ? 'selected' : ''}`}
              >
                <img
                  src='/icons-cremen-svg/hotel.svg'
                  onClick={() => handleCategoryClick('hotel')}
                  className={`${pageState.category == 'hotel' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Hotel para mascotas</p>
              </div>
              <div
                className={`icon-container-ext ${pageState.isHidden ? 'hidden' : 'visible'} ${pageState.selectedCategory === 'transportation' ? 'selected' : ''}`}
              >
                <img
                  src='/icons-cremen-svg/domicilio.svg'
                  onClick={() => handleCategoryClick('transportation')}
                  className={`${pageState.category == 'transportation' ? 'glow' : ''}`}
                  width={70}
                  height={70}
                />
                <p>Transporte de mascotas</p>
              </div>
            </Flex>
          </Flex>
        </div>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyATf5aHQuqOmjkAnl69AB0f_xWJQ-pu9ck' }}
          center={mapCoords}
          draggable={true}
          zoom={mapZoom}
          options={() => {
            return {
              gestureHandling: 'greedy'
            };
          }}
        >
          {dataList.map((element) => {
            const icon = pageState.category !== '' ? iconMap[pageState.category] : iconMarker[element.principalStoreType.keyword];
            return (
              <Image
                src={icon}
                width={40}
                preview={false}
                lat={element.coordX}
                lng={element.coordY}
                onClick={() => onPinClick({ lat: element.coordX, lng: element.coordY, modalId: element.id })}
              />
            );
          })}
        </GoogleMapReact>

        {dataList.map((entity) => {
          const iconSrc = entity.principalStoreType ? iconDetail[entity.principalStoreType.keyword] : '/icons-cremen-svg/clinic.svg';
          return (
            <Modal
              className='to-detail-modal'
              open={showModal.modalId == entity.id && showModal.show}
              footer={null}
              closable={false}
              onCancel={() => handleCancel(entity.id)}
            >
              <img className='type-store-icon' src={iconSrc} />
              <div className='modal-detail'>
                <h2>{entity.name}</h2>
                <p className='entity-address-modal'>
                  <img className='icon-ubi-modal' src='/icon-categories/contact-details/UBI.png' />
                  {entity.address}
                </p>
              </div>
              <button
                className='to-detail-btn'
                type='link'
                onClick={() => {
                  return entity.username ? navigate(`/detalle/${entity.username}`) : navigate('/404');
                }}
              >
                {' '}
                Ver más{' '}
              </button>
            </Modal>
          );
        })}
      </div>
    </div>
  );
}

export default Map;
