import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { usePageState } from './StateContextPage';

function ButtonBack() {
  const navigate = useNavigate();
  const { pageState } = usePageState();

  const handleBack = () => {
    localStorage.setItem('pageState', JSON.stringify(pageState));
    navigate('/');
  };

  return <LeftOutlined className='btn-back' onClick={handleBack} />;
}

export default ButtonBack;
