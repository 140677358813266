import './App.css';
import { Input } from 'antd';
import AutocompleteComponent from './components/AutoCompleteExtend';
// import AutoComplete from 'react-google-autocomplete';
// import { useState } from 'react';
const { Search } = Input;

/**Esta funcion valida que el navegador tenga geolocalizacion a traves de la API
obtiene la pocision geografica del navegador, desestructura el objeto
y obtiene los valores de latitud y longitud, luego los envia a onSearch 
que procesa las coordenadas obtenidas*/
function SearchAddressComponent({ onSearch }) {
  const searchByGPS = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        onSearch({ latitude, longitude });
      });
    } else {
      alert('Error de geolocalizacion');
    }
  };

  return (
    <div>
      <AutocompleteComponent onSearch={onSearch} />
      <div>
        <button className='gps-btn' onClick={searchByGPS} type='primary' size='large' style={{ marginTop: '10px' }}>
          Buscar por GPS
        </button>
      </div>
    </div>
  );
}

export default SearchAddressComponent;
