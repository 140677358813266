import { Modal } from 'antd';
import React from 'react';
import AutocompleteComponent from './AutoCompleteExtend';

const ModalForInput = ({ visible, onClose, onSearch }) => {
  return (
    <Modal
      style={{ backgroundColor: '#fdbf94', borderRadius: '60px' }}
      visible={visible}
      onCancel={onClose}
      footer={[<AutocompleteComponent onSearch={onSearch} />]}
      closable={false}
    >
      <p
        style={{
          fontSize: 'medium',
          fontFamily: 'verdana',
          fontWeight: '600',
          padding: '8px',
          margin: 'auto',
          marginBottom: '-8px',
          width: 'fit-content',
          textAlign: 'center',
          color: 'white',
          backgroundColor: '#fdbf94',
          borderRadius: '20px',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)'
        }}
      >
        Ingrese una nueva dirección
      </p>
    </Modal>
  );
};

export default ModalForInput;
