import React from 'react';
import { iconDetail } from './IconConfig';
import { Flex } from 'antd';
import '../styles/StoreTypesIcons.css';

const StoreTypesIcons = ({ storeTypes = [] }) => {
  let keywords = storeTypes.map((k) => k.keyword);
  return (
    <>
      {keywords.map((keyword) => (
        <img className='icons-secondary-services' key={keyword} src={iconDetail[keyword]} />
      ))}
    </>
  );
};
export default StoreTypesIcons;
