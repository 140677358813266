import './styles/Detail.css';
import SimpleMap from './components/SimpleMap';
import Schedule from './components/FormatSchedule';
import ButtonBack from './components/ButtonBack';
import Appointment from './components/Appointment';
import { Flex, Col, Row } from 'antd';
import { useState, useEffect, React } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { iconDetail, iconMarker } from './components/IconConfig';
import StoreTypesIcons from './components/StoreTypesIcons';

const client = new ApolloClient({
  uri: 'https://thecapy-mascot-dcea54a4d4c2.herokuapp.com/api/graphql',
  cache: new InMemoryCache()
});

function DetailPage() {
  const [data, setData] = useState({});
  const [showMap, setShowMap] = useState(true);
  const [showAppointment, setShowAppointment] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [activeItem, setActiveItem] = useState('map');
  const { username } = useParams();

  useEffect(() => {
    client
      .query({
        query: gql`
          query Stores($username: String!) {
            stores(where: { username: { equals: $username } }) {
              id
              name
              address
              coordX
              coordY
              schedule
              phone
              instagram
              facebook
              webpage
              email
              onlyDelivery
              storeType {
                keyword
              }
              weeklySchedule {
                id
              }
              principalStoreType {
                keyword
              }
              services {
                name
                id
              }
              payments {
                name
              }
              animals {
                name
              }
              surgeries {
                name
              }
              specialties {
                name
              }
              exams {
                name
              }
            }
          }
        `,
        variables: {
          username
        }
      })
      .then((result) => {
        if (result.data.stores.length > 0) {
          const storeData = result.data.stores[0];
          const storeId = storeData.id;

          client
            .query({
              query: gql`
                query StoreServices($storeId: ID!) {
                  storeServices(where: { store: { id: { equals: $storeId } } }) {
                    id
                    name
                    price
                    duration
                    description
                    service {
                      name
                      id
                    }
                  }
                }
              `,
              variables: {
                storeId
              }
            })
            .then((storeServiceResult) => {
              const storeServices = storeServiceResult.data.storeServices;
              const combinedData = { ...storeData, storeServices };
              setData(combinedData);
            });
        }
      });
  }, []);

  const coords = data ? { lat: parseFloat(data.coordX), lng: parseFloat(data.coordY) } : null;
  const iconSrc = data.principalStoreType ? iconDetail[data.principalStoreType.keyword] : '/icons-cremen-svg/clinic.svg';
  const markerIcon = data.principalStoreType ? iconMarker[data.principalStoreType.keyword] : '/icons-cremen-svg/clinic.svg';

  /**
   * Renderiza los servicios que tiene una veterinaria.
   * Si la condicion se cumple, se mapea el array
   * Retorna una linea de parrafo con el nombre de los servicios y un className
   * Obtiene el id y se asigna como key para listar los servicios.
   * De no cumplirse la condicion se retorna null
   */
  const renderServices = () => {
    if (!data || !data.services) {
      return null;
    }
    const consMedVet = data.services.filter((service) => service.id === 'clszg2wmn0000h2wxrc68zwjx');
    const otherServices = data.services.filter((service) => service.id !== 'clszg2wmn0000h2wxrc68zwjx');
    const serviceStoreType = data.storeType?.map((storeType) => storeType.keyword) || [];

    const { surgeries, specialties } = data;

    return [...consMedVet, ...otherServices, ...specialties, ...surgeries].map((service) => (
      <p
        className={`service-detail ${service.__typename == 'Specialism' && 'bg-specialism'} ${service.__typename == 'Service' && 'bg-service'} ${service.__typename == 'Surgery' && 'bg-surgery'}`}
        key={service.id}
      >
        {service.__typename == 'Surgery' && 'Procedimiento:'} {service.name}
      </p>
    ));
  };

  /**
   * Maneja el item seleccionado de la barra de navegacion lateral.
   * Gestiona el icono seleccionado y resalta el nombre en bold
   */
  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const checkHeaderImage = () => {
    const storeTypeMap = {
      vet: 'header'
    };
    data?.principalStoreType?.keyword == 'vet' && 'header-vet';
  };

  return (
    <>
      <ApolloProvider client={client}>
        <Row>
          <Col
            xs={{
              span: 5,
              offset: 1
            }}
            lg={{
              span: 6,
              offset: 2
            }}
          >
            <Flex className='flex-container-1'>
              <Flex vertical={true} justify='center' className='detail-navigation'>
                <ButtonBack />
                <div
                  className='map-container-btn'
                  onClick={() => {
                    handleItemClick('map');
                    setShowMap(true);
                    setShowAppointment(false);
                  }}
                >
                  <img className='icons-desk' src='/icon-categories/detail-icon/UBI.svg' />
                  <p className='map-txt-btn' style={{ fontWeight: activeItem === 'map' ? 'bold' : 'normal' }}>
                    Mapa
                  </p>
                </div>
                <div
                  className='contact-container-btn'
                  onClick={() => {
                    handleItemClick('contact');
                    setShowContact(true);
                    setShowMap(false);
                    setShowAppointment(false);
                  }}
                >
                  <img className='icons-desk' src='/icon-categories/detail-icon/TEL.svg' />
                  <p className='contact-txt-btn' style={{ fontWeight: activeItem === 'contact' ? 'bold' : 'normal' }}>
                    Contacto
                  </p>
                </div>
                <div
                  className='appointment-container-btn'
                  onClick={() => {
                    handleItemClick('schedule');
                    setShowMap(false);
                    setShowContact(false);
                    setShowAppointment(true);
                  }}
                >
                  {/* <img className='icons-desk' src='/icon-categories/detail-icon/AGENDA.svg' />
                  <p className='appointment-txt-btn' style={{ fontWeight: activeItem === 'schedule' ? 'bold' : 'normal' }}>
                    Agendar
                  </p> */}
                </div>
                {/* {<div onClick={() => handleItemClick('favorites')}>
              <img className='icons-desk' src='icon-categories/COR.svg'/>
                <p style={{ fontWeight: activeItem === 'favorites' ? 'bold' : 'normal' }}>Favoritos</p>
              </div>} */}
              </Flex>
            </Flex>
          </Col>
          <Col
            xs={{
              span: 11,
              offset: 1
            }}
            lg={{
              span: 6,
              offset: 2
            }}
          >
            <Flex className='flex-container-2' vertical={true} align='center'>
              <Flex justify='center'>
                <a href='https://www.app.fivemy.cl/'>
                  <img src='/name-cream.png' className='name-logo' />
                </a>
              </Flex>
              <Flex>
                <div className={`header-image header-${data?.principalStoreType?.keyword}`} />
              </Flex>
              <Flex vertical={true} align='center'>
                <Flex className='vet-description'>
                  <img className='icon-description' src={iconSrc} />
                  <ul>
                    <div className='vet-description-txt-1'>
                      <h2>{data.name}</h2>
                    </div>
                    <span className='vet-description-txt-2'>
                      <Schedule data={data} />
                    </span>
                    <span className='vet-description-txt-3'>
                      <StoreTypesIcons storeTypes={data.storeType} />
                    </span>
                  </ul>
                  <ul>
                    <Flex className='flex-service-detail' wrap='wrap' gap='small'>
                      {renderServices()}
                    </Flex>
                  </ul>
                  <ul className='animal-type'>
                    {data.animals && data.animals.some((animal) => animal.name === 'Animales Exóticos') && (
                      <img src='/icon-categories/detail-icon/EXOTICO_ACREMA.svg' />
                    )}
                    {data.animals && data.animals.some((animal) => animal.name === 'Animales Exóticos') && (
                      <span className='animal-txt-1'>Mascotas exoticas</span>
                    )}
                    {data.animals && data.animals.some((animal) => animal.name === 'Perros' || animal.name === 'Gatos') && (
                      <img src='/icon-categories/detail-icon/PERROGATO_ACREMA.svg' />
                    )}
                    {data.animals && data.animals.some((animal) => animal.name === 'Perros' || animal.name === 'Gatos') && (
                      <span className='animal-txt'>Perros y gatos</span>
                    )}
                    {data.animals &&
                      data.animals.some(
                        (animal) => animal.name === 'Perros' || animal.name === 'Gatos' || animal.name === 'Animales Exóticos'
                      ) && <span className='animal-type-txt'>Atiende: </span>}
                  </ul>
                </Flex>
              </Flex>
              {showMap && coords.lat && coords.lng && !data.onlyDelivery ? (
                <Flex vertical={true} className='vet-map'>
                  <img className='icon-map' src='/icon-categories/detail-icon/UBI.svg' />
                  <p>{data.address}</p>
                  <SimpleMap center={{ lat: coords.lat, lng: coords.lng }} zoom={19} markerIcon={markerIcon} />
                </Flex>
              ) : (
                ''
              )}
              {!showMap && showContact && (
                <Flex vertical={false} justify='center'>
                  <div className='vet-details'>
                    <ul>
                      <li>
                        <img className='icons-details' src='/icon-categories/contact-details/tel.svg' />{' '}
                        {data.phone ? <a href={`tel:${data.phone}`}>{data.phone}</a> : <span>Sin información.</span>}
                      </li>
                      <li>
                        <img className='icons-details' src='/icon-categories/contact-details/fb.svg' />{' '}
                        {data.facebook ? (
                          <a href={data.facebook} target='_blank' rel='noreferrer'>
                            {data.facebook}
                          </a>
                        ) : (
                          <span>Sin información.</span>
                        )}
                      </li>
                      <li>
                        <img className='icons-details' src='/icon-categories/contact-details/ig.svg' />{' '}
                        {data.instagram ? (
                          <a href={`https://www.instagram.com/${data.instagram}`} target='_blank' rel='noreferrer'>
                            {data.instagram}
                          </a>
                        ) : (
                          <span>Sin información.</span>
                        )}
                      </li>
                      <li>
                        <img className='icons-details' src='/icon-categories/contact-details/web.svg' />{' '}
                        {data.webpage ? (
                          <a href={data.webpage} target='_blank' rel='noreferrer'>
                            {data.webpage}
                          </a>
                        ) : (
                          <span>Sin información.</span>
                        )}
                      </li>
                      <li>
                        <img className='icons-details' src='/icon-categories/contact-details/mail.svg' />{' '}
                        {data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : <span>Sin información.</span>}
                      </li>
                    </ul>
                  </div>
                  <div className='ad1'>
                    <h1> </h1>
                  </div>
                </Flex>
              )}
              {showAppointment && !showContact && data.weeklySchedule && data.weeklySchedule.id && (
                <Flex>
                  <div>
                    <Appointment scheduleId={data.weeklySchedule.id} storeServices={data.storeServices} storeId={data.id} />
                  </div>
                </Flex>
              )}
            </Flex>
          </Col>
          <Col
            xs={{
              span: 5,
              offset: 1
            }}
            lg={{
              span: 6,
              offset: 2
            }}
          >
            <Flex vertical={true} align='center' className='flex-container-3'>
              <Flex className='ad2'>
                <div>
                  <h1> </h1>
                </div>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </ApolloProvider>
    </>
  );
}

export default DetailPage;
