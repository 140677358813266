import React, { useState, useEffect } from 'react';
import { Button, Divider, Space, Tooltip, Alert, theme, Calendar, ConfigProvider } from 'antd';
import moment from 'moment';
import esES from 'antd/es/locale/es_ES';

const generateTimeSlots = (start, end, interval = 20) => {
  let startTime = moment(start);
  let endTime = moment(end);
  let currentTime = startTime.clone();
  let timeSlots = [];

  while (currentTime <= endTime) {
    timeSlots.push(currentTime.format('HH:mm'));
    currentTime = currentTime.add(interval, 'minutes');
  }

  return timeSlots;
};

const splitTimeSlots = (slotsForDay, schedule) => {
  let storeCloseTime = moment(schedule.endTime).subtract(10, 'minutes').format('HH:mm');
  let morningEnd = moment('13:00', 'HH:mm');
  let morningSlots = [];
  let afternoonEnd = moment(storeCloseTime, 'HH:mm');
  let afternoonSlots = [];

  slotsForDay.forEach((time) => {
    let timeMoment = moment(time, 'HH:mm');
    if (timeMoment.isBefore(morningEnd)) {
      morningSlots.push(time);
    } else if (timeMoment.isBefore(afternoonEnd)) {
      afternoonSlots.push(time);
    }
  });
  return { morningSlots, afternoonSlots };
};

const TimeTable = ({ schedule, appointments, onTimestampChange }) => {
  const [value, setValue] = useState(null);
  const [dates, setDates] = useState([]);
  const [hours, setHours] = useState([]);
  const [appointmentSlots, setAppointmentSlots] = useState({});
  const [selectedTime, setSelectedTime] = useState(null);
  const selectedDateSlots = appointmentSlots[value ? value.format('YYYY-MM-DD') : []] || [];
  let startTime = schedule.startTime;
  let endTime = schedule.endTime;
  let timeSlots = generateTimeSlots(startTime, endTime);
  let { morningSlots, afternoonSlots } = splitTimeSlots(selectedDateSlots, schedule);
  let allowedDays = schedule.day;
  let dayNameToNumber = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
  };
  let allowedDayNumbers = allowedDays.map((day) => dayNameToNumber[day]);

  useEffect(() => {
    if (appointments && appointments.length > 0) {
      const dateAppointment = appointments.map((appointment) => ({
        startTime: moment(appointment.startTime).format('YYYY-MM-DD')
      }));
      const dateAppointmentHour = appointments.map((appointment) => ({
        startTime: moment(appointment.startTime).format('YYYY-MM-DD HH:mm')
      }));
      setDates(dateAppointment);
      setHours(dateAppointmentHour);
    }
  }, [appointments]);

  const { token } = theme.useToken();
  const wrapperStyle = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG
  };
  const onPanelChange = (value) => {
    let appointmentDay = value.format('YYYY-MM-DD');
    let isDateInList = dates.some((date) => date.startTime === appointmentDay);

    if (isDateInList) {
      let hoursSet = new Set(hours.filter((hour) => hour.startTime.startsWith(appointmentDay)).map((hour) => hour.startTime.split(' ')[1]));
      let filteredSlots = timeSlots.filter((time) => !hoursSet.has(time));
      setAppointmentSlots((prevSlots) => ({
        ...prevSlots,
        [appointmentDay]: filteredSlots
      }));
    } else {
      setAppointmentSlots((prevSlots) => ({
        ...prevSlots,
        [appointmentDay]: timeSlots
      }));
    }
    setValue(value);
  };

  const disabledDate = (current) => {
    if (!current) {
      return false;
    }
    let isPastDate = current < moment().startOf('day');
    let dayOfWeek = current.day();
    return isPastDate || !allowedDayNumbers.includes(dayOfWeek);
  };

  const handleTimeClick = (time) => {
    if (value && time) {
      const combinedTimestamp = moment(`${value.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss');
      setSelectedTime(time);

      if (onTimestampChange) {
        onTimestampChange(combinedTimestamp);
      }
    }
  };

  return (
    <>
      <div style={wrapperStyle}>
        <ConfigProvider locale={esES}>
          <Calendar disabledDate={disabledDate} fullscreen={false} onSelect={onPanelChange} />
        </ConfigProvider>
      </div>
      <div>
        {value && selectedTime && (
          <Alert message={`Fecha seleccionada: ${value.format('DD-MM-YYYY')} a las ${selectedTime}`} type='warning' />
        )}
        {!selectedTime && value && <Alert message={`Seleccione una hora disponible`} type='warning' />}
        {value ? (
          <>
            <Divider orientation='left'>Mañana</Divider>
            <Space wrap>
              {morningSlots.map((time, index) => (
                <Tooltip time={time} key={index}>
                  <Button onClick={() => handleTimeClick(time)}>{time}</Button>
                </Tooltip>
              ))}
            </Space>
            <Divider orientation='left'>Tarde</Divider>
            <Space wrap>
              {afternoonSlots.map((time, index) => (
                <Tooltip time={time} key={index}>
                  <Button onClick={() => handleTimeClick(time)}>{time}</Button>
                </Tooltip>
              ))}
            </Space>
          </>
        ) : (
          <Alert message={`Seleccione una fecha disponible del calendario`} type='warning' />
        )}
      </div>
    </>
  );
};
export default TimeTable;
