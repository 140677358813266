import React from 'react';

/**
 * Convierte un string con \n literal, en un Array de strings con saltos de linea reales.
 * Recibe el objeto vet y toma su atributo schedule,
 * Guarda en una variable, un string con los \n literales reemplazados por reales,
 * Almacena un Array en la variable lines, haciendo un corte del mismo en cada \n
 * Mapea cada linea en un div si no es la ultima linea inserta un <br/> despues de ella
 * Si se cumplea la condicion de existir vet y vet.schedule renderiza el resultado.
 */
function Schedule({ data }) {
  const formatSchedule = (schedule) => {
    let correcteSchedule = schedule.replace(/\\n/g, '\n');
    let lines = correcteSchedule.split('\n');

    return lines.map((line, index) => (
      <div key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </div>
    ));
  };

  return <div>{data && data.schedule ? formatSchedule(data.schedule) : 'No hay registro de horarios'}</div>;
}

export default Schedule;
