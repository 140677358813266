import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import '../styles/Appointment.css';
import { Flex, Modal, Form, Input, Button, notification } from 'antd';
import TimeTable from './TimeTable';
import moment from 'moment';

const client = new ApolloClient({
  uri: 'https://thecapy-mascot-dcea54a4d4c2.herokuapp.com/api/graphql',
  cache: new InMemoryCache()
});

const Appointment = ({ scheduleId, storeServices, storeId }) => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointmentDates, setSelectedAppointmentDates] = useState(false);
  const [timestamp, setTimestamp] = useState([]);

  const handleServiceClick = (service) => {
    setSelectedService({ ...service, schedule: data });
    setOpen(true);
  };

  const handleTimestampChange = (newTimestamp) => {
    let newStartTime = moment(newTimestamp, 'YYYY-MM-DD HH:mm:ss');
    let newEndTime = newStartTime.clone().add(20, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    setTimestamp([newStartTime, newEndTime]);
  };

  useEffect(() => {
    if (!storeId) {
      return;
    }

    client
      .query({
        query: gql`
          query Appointments($storeId: ID!) {
            appointments(where: { storeId: { id: { equals: $storeId } } }) {
              id
              startTime
              endTime
              storeId {
                id
                name
              }
              storeServiceId {
                id
              }
              day
            }
          }
        `,
        variables: { storeId }
      })
      .then((result) => {
        setAppointments(result.data.appointments || []);
      });
  }, [storeId]);

  useEffect(() => {
    if (!scheduleId) {
      return;
    }

    client
      .query({
        query: gql`
          query Schedule($scheduleId: ID!) {
            schedule(where: { id: $scheduleId }) {
              id
              startTime
              endTime
              day
              storeId {
                id
                name
              }
            }
          }
        `,
        variables: { scheduleId }
      })
      .then((result) => {
        if (result.data.schedule) {
          setData(result.data.schedule);
        }
      });
  }, [scheduleId]);

  let newStartTime = moment(timestamp[0]).toISOString();
  let newEndTime = moment(timestamp[1]).toISOString();

  const handleSubmit = (values) => {
    const appointmentData = {
      startTime: newStartTime,
      endTime: newEndTime,
      storeId: { connect: { id: storeId } },
      clientId: values.clientId,
      storeServiceId: { connect: { id: selectedService.id } },
      ownerName: values.ownerName,
      petName: values.petName,
      phone: values.phone,
      email: values.email,
      description: values.description,
      day: values.day
    };

    console.log('Appointment Data:', appointmentData);

    client
      .mutate({
        mutation: gql`
          mutation CreateAppointment($data: AppointmentCreateInput!) {
            createAppointment(data: $data) {
              startTime
              endTime
              storeId {
                id
              }
              clientId {
                id
              }
              storeServiceId {
                id
              }
              ownerName
              petName
              phone
              email
              description
              day
            }
          }
        `,
        variables: { data: appointmentData }
      })
      .then((result) => {
        notification.success({
          message: 'Cita Programada',
          description: 'Su cita ha sido programada, pronto te contactaran para confirmar los datos ingresados.',
          placement: 'top'
        });
        setTimestamp([]), setOpen(false), setSelectedAppointmentDates(false);
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: 'Hubo un error al programar la cita. Por favor, inténtelo de nuevo.',
          placement: 'top'
        });
        console.error('error', error);
      });
  };

  if (!data) {
    return <div>Cargando...</div>;
  }

  const isTimestampSet = timestamp.length > 0 && timestamp.every((time) => moment(time).isValid());

  return (
    <>
      <div className='main-container'>
        {storeServices.map((service) => (
          <Flex className='appointment-container' key={service.id}>
            <div>
              <h3>{service.name}</h3>
              <p>{service.description}</p>
              <p>Precio: ${service.price}</p>
              <p>Duración: {service.duration} min</p>
              <button className='to-detail-btn' type='link' onClick={() => handleServiceClick(service)}>
                Seleccionar
              </button>
            </div>
          </Flex>
        ))}
      </div>
      <Modal
        style={{
          backgroundColor: 'white',
          borderRadius: '20px'
        }}
        title='Agendar una hora'
        centered
        destroyOnClose={true}
        open={open}
        closable={false}
        width={1000}
        height={'fit-content'}
        footer={[
          <Button
            className='to-detail-btn'
            onClick={() => {
              setTimestamp([]), setOpen(false), setSelectedAppointmentDates(false);
            }}
            style={{ marginRight: 8 }}
          >
            Cancelar
          </Button>,
          selectedAppointmentDates ? (
            <Button key='submit' className='to-detail-btn' htmlType='submit' form='appointment-form'>
              Enviar
            </Button>
          ) : (
            isTimestampSet && (
              <Button
                className='to-detail-btn'
                onClick={() => {
                  setOpen(true);
                  setSelectedAppointmentDates(true);
                }}
              >
                Siguiente
              </Button>
            )
          )
        ]}
      >
        {selectedService && appointments && !selectedAppointmentDates ? (
          <>
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <div className='appointment-container-time-table'>
                  <h3>{selectedService.name}</h3>
                  <p>Precio: ${selectedService.price}</p>
                  <p>Duración: {selectedService.duration} min</p>
                </div>
              </div>
              <h4>Horarios disponibles:</h4>
              <div>
                <div className='schedule-appointment-container'>
                  <TimeTable schedule={data} appointments={appointments} onTimestampChange={handleTimestampChange} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Form
            id='appointment-form'
            layout='vertical'
            onFinish={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxWidth: 600,
              backgroundColor: 'white',
              borderRadius: '20px',
              padding: '20px',
              margin: 'auto'
            }}
          >
            <p>Ingrese los datos de contacto para registrar su agendamiento</p>
            <Form.Item name='ownerName' rules={[{ required: true, message: 'Por favor ingrese el nombre del dueño de la mascota' }]}>
              <Input placeholder='Nombre del dueño' />
            </Form.Item>
            <Form.Item name='petName' rules={[{ required: true, message: 'Por favor ingrese el nombre de la mascota' }]}>
              <Input placeholder='Nombre del paciente/mascota' />
            </Form.Item>
            <Form.Item name='phone' rules={[{ required: true, message: 'Por favor ingrese su numero de telefono' }]}>
              <Input placeholder='Telefono' />
            </Form.Item>
            <Form.Item name='email' rules={[{ required: true, message: 'Por favor ingrese su email' }]}>
              <Input placeholder='Correo Electronico' />
            </Form.Item>
            <Form.Item name='description'>
              <Input placeholder='Observacion' />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default Appointment;
