import logo from './logo.svg';
import './App.css';
import { Helmet } from 'react-helmet';

import { Flex } from 'antd';
import { useState, useEffect } from 'react';

import Map from './Map';
import SearchAddressComponent from './SearchAddressComponent';
import Detail from './Detail';
import PageNotFound from './components/ErrorPage';
// import TestMap from './TestMap'

import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';

import { Routes, Route, Outlet, Link, useRoutes } from 'react-router-dom';

//  https://thecapy-mascot-dcea54a4d4c2.herokuapp.com
const client = new ApolloClient({
  // uri: 'http://localhost:3000/api/graphql',
  uri: 'https://thecapy-mascot-dcea54a4d4c2.herokuapp.com/api/graphql',
  cache: new InMemoryCache()
});

const boxStyle = {
  width: '100%',
  height: 120
};

function App() {
  const [showMap, setShowMap] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vets, setVets] = useState([]);
  const [stores, setStores] = useState([]);
  const [coords, setCoords] = useState({});

  useEffect(() => {
    client
      .query({
        query: gql`
          query Store {
            stores {
              username
              id
              name
              address
              coordX
              coordY
              deliveryService
              active
              onlyDelivery
              principalStoreType {
                keyword
              }
              storeType {
                keyword
              }
              services {
                name
              }
              payments {
                name
              }
              animals {
                name
              }
              surgeries {
                name
              }
              specialties {
                name
              }
              exams {
                name
              }
            }
          }
        `
      })
      .then((result) => {
        setStores(result.data.stores);
      });

    setTimeout(() => {
      setLoading(false);
      setShowSearch(true);
    }, 2000);
  }, []);

  const myStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/fondo2.png'})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat'
  };

  /* Si recibe un input crea la variable latitud y longitud
  si el input viene del Autocomplete, verifica si tiene geometry y location 
  luego asigna los valores lat y lng a las variables creadas
  si al recibir los datos de searchByGPS, verifica si tiene latitud y longitud 
  luego asigna los valores a las variables respectivas
  ya obtenidas las coordenadas válidas, actualiza el estado coords y 
  gestiona la visualización de componentes.*/
  const onSearch = (input) => {
    if (input) {
      var latitude, longitude;
      if (input.geometry && input.geometry.location) {
        latitude = input.geometry.location.lat();
        longitude = input.geometry.location.lng();
      } else if (input.latitude && input.longitude) {
        latitude = input.latitude;
        longitude = input.longitude;
      }
      if (latitude && longitude)
        setCoords({
          lat: latitude,
          lng: longitude
        });

      setShowSearch(false);
      setShowMap(true);
    }
  };

  const AppComponent = () => {
    return (
      <div className='App' style={myStyle}>
        <Helmet>
          <meta charSet='utf-8' />
          {window.location.hostname.includes('demo.fivemy') && <meta name='robots' content='noindex' />}
        </Helmet>
        {loading && (
          <Flex style={boxStyle} justify={'center'} align={'center'}>
            <img className='animation' src='cute.png' width={300} />
          </Flex>
        )}
        {showMap && !loading && <Map stores={stores} coords={coords} onSearch={onSearch} />}
        {showSearch && <SearchAddressComponent onSearch={onSearch} className='search' />}
      </div>
    );
  };

  let routes = useRoutes([
    { path: '/', element: <AppComponent /> },
    { path: '/detalle/:username', element: <Detail /> },
    { path: '/404', element: <PageNotFound /> }
  ]);
  return routes;
}

export default App;
