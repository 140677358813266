import React, { useState, useEffect } from 'react';
import AutoComplete from 'react-google-autocomplete';

const AutocompleteComponent = ({ onSearch }) => {
  const [addressSelected, setAddressSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');

  /** Almacena el place seleccionado del autocomplete en el cache del navegador
   * muestra en el placeholder del componente la direccion del cache
   * al cerrar el navegador o actualizar la pagina se reinicia el cache */
  useEffect(() => {
    const savedPlace = sessionStorage.getItem('selectedPlace');
    if (savedPlace) {
      setInputValue(savedPlace);
    }
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('selectedPlace');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  /** Maneja que la direccion sea obtenida del autocompletar setea lat y lng */
  const handlePlaceSelected = (place) => {
    if (place && place.geometry) {
      setAddressSelected(true);
      let placeName = place.formatted_address;
      setInputValue(placeName);
      sessionStorage.setItem('selectedPlace', placeName);
      onSearch({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        geometry: place.geometry,
        location: place.geometry.location
      });
    }
  };
  /** Maneja el funcionamiento por defecto de "Enter" en el buscador de autocompletar evitando enviar cadenas vacias*/
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!addressSelected || inputValue.trim() === '') {
        event.preventDefault();
      }
    }
  };
  /** Maneja el cambio de la entrada en el autocompletar y actualiza las variables de estado*/
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setAddressSelected(false);
  };

  return (
    <AutoComplete
      onKeyDown={handleKeyDown}
      onPlaceSelected={handlePlaceSelected}
      onChange={handleInputChange}
      value={inputValue}
      apiKey={'AIzaSyATf5aHQuqOmjkAnl69AB0f_xWJQ-pu9ck'}
      placeholder={'Escriba su dirección para empezar'}
      options={{
        types: ['address'],
        componentRestrictions: { country: 'cl' }
      }}
      language='es'
    />
  );
};

export default AutocompleteComponent;
