/**
 * Objetos con ruta de los iconos para renderizar en base a PST
 */

//Agrega la ruta para la pagina detalle
export let iconDetail = {
  vet: '/icons-cremen-svg/clinic.svg',
  petshop: '/icons-cremen-svg/shop.svg',
  funeral: '/icons-cremen-svg/cementerio.svg',
  hairsalon: '/icons-cremen-svg/cut.svg',
  pharmacy: '/icons-cremen-svg/farma.svg',
  foodstore: '/icons-cremen-svg/alimento.svg',
  training: '/icons-cremen-svg/learn.svg',
  hotel: '/icons-cremen-svg/hotel.svg',
  petwalk: '/icons-cremen-svg/paseo.svg',
  transportation: '/icons-cremen-svg/domicilio.svg'
};

//Agrega la ruta para los marcadores de los mapas
export let iconMarker = {
  vet: '/icons-cremen-svg/clinic.svg',
  petshop: '/icons-cremen-svg/shop.svg',
  funeral: '/icons-cremen-svg/cementerio.svg',
  hairsalon: '/icons-cremen-svg/cut.svg',
  pharmacy: '/icons-cremen-svg/farma.svg',
  foodstore: '/icons-cremen-svg/alimento.svg',
  training: '/icons-cremen-svg/learn.svg',
  hotel: '/icons-cremen-svg/hotel.svg',
  petwalk: '/icons-cremen-svg/paseo.svg',
  transportation: '/icons-cremen-svg/domicilio.svg'
};
//Ruta en base a category para mostrar marcadores por filtro
export let iconMap = {
  vet: '/icons-cremen-svg/clinic.svg',
  shop: '/icons-cremen-svg/shop.svg',
  funerary: '/icons-cremen-svg/cementerio.svg',
  hairsalon: '/icons-cremen-svg/cut.svg',
  pharmacy: '/icons-cremen-svg/farma.svg',
  food: '/icons-cremen-svg/alimento.svg',
  training: '/icons-cremen-svg/learn.svg',
  hotel: '/icons-cremen-svg/hotel.svg',
  petwalk: '/icons-cremen-svg/paseo.svg',
  transportation: '/icons-cremen-svg/domicilio.svg'
};
