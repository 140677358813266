import React from 'react';

const Marker = ({ icon }) => (
  <img
    src={icon}
    style={{
      height: '40px',
      width: '40px'
    }}
  />
);

export default Marker;
