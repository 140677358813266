import React from 'react';
import { useNavigate } from 'react-router-dom';
import { iconDetail, iconMap } from './IconConfig';

const VetStoreList = ({ dataList = [], category }) => {
  const navigate = useNavigate();

  return (
    <div className='list-component-content'>
      <ul className='list-items'>
        {dataList.map((entity, index) => {
          const iconSrc = category !== '' ? iconMap[category] : iconDetail[entity.principalStoreType.keyword];

          return (
            <li className='list-item' key={index}>
              <div className='entity-info'>
                <div className='entity-name'>
                  <img className='list-logo' src={iconSrc} />
                  {entity.name}
                </div>
              </div>
              <button
                className='btn-go-detail'
                onClick={() => {
                  return entity.username ? navigate(`/detalle/${entity.username}`) : navigate('/404');
                }}
              >
                Ver más
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VetStoreList;
