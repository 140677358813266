import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

const SimpleMap = ({ center, zoom, markerIcon }) => {
  return (
    <div className='map-size' style={{ width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyATf5aHQuqOmjkAnl69AB0f_xWJQ-pu9ck' }}
        defaultCenter={center}
        defaultZoom={zoom}
        draggable={true}
        zoomControl={true}
        fullScreenControl={true}
        options={() => {
          return {
            gestureHandling: 'greedy'
          };
        }}
      >
        <Marker icon={markerIcon} lat={center.lat} lng={center.lng} />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
